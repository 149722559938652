export enum CountriesAllowed {
  'ar' = 'ar',
  'pe' = 'pe',
}

export const CountriesConfig = {
  [CountriesAllowed.ar]: {
    label: 'Argentina',
    image: 'https://pedidosya.dhmedia.io/image/pedidosya/flags/v2/flag-ar.png?quality=70',
    stgUrl: 'https://stg-mweb.pedidosya.com.ar',
    liveUrl: 'https://www.pedidosya.com.ar',
  },
  [CountriesAllowed.pe]: {
    label: 'Peru',
    image: 'https://pedidosya.dhmedia.io/image/pedidosya/flags/v2/flag-pe.png?quality=70',
    stgUrl: 'https://stg-mweb.pedidosya.com.pe',
    liveUrl: 'https://www.pedidosya.com.pe',
  },
};
